* {
  font-family: sans-serif;
}

body, html {
  margin: 0 !important;
  padding: 0 !important;
}

body > div {
  min-height: 100vh;
  background-color: #2B2B2B;
}

.visible-scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  height: 0px;
}

.visible-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888888;
  -webkit-box-shadow: 0 0 1px #888888;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #1C221E;
  -webkit-box-shadow: 0 0 1px #1C221E;
}