.custom-suggestions-container {
    background: white;
    border-top: none;
    z-index: 10000000;
}

.custom-suggestions-container ul {
    list-style-type: none;
    padding: 0; 
    margin: 0;     
    z-index: 10000000;
}

.custom-suggestion {
    padding: 4px 8px;
    cursor: pointer;
    font-size: 14px;
    border-bottom: 1px solid #666;
    color: black;
    z-index: 10000000;
}

.custom-suggestion-highlighted {
    background: #666;
}